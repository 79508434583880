import styled from 'styled-components';

export const ModalContainer = styled.div`
  margin: 20px;
`;

export const ModalContent = styled.div`
  margin-bottom: 24px;
  max-height: 360px;
  max-width: 420px;
`;

export const ModalAction = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;
