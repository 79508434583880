export const TENANTS_ROUTE = 'v1/tenants';
export const INSTALL_CHATBOT_ROUTE = 'v1/installations';
export const PACK_INSTALLATIONS_ROUTE = 'v1/packs/installation';
export const PLUGIN_ROUTE = 'v1/configurations/application';
export const APPLICATION_ROUTE = 'v1/packs';
export const INSTALLATIONS_PROCESS_STATUS = 'v1/statusprocess';
export const VALIDATE_INSTALLATION_EXISTS = 'v1/installations/validate-exists';
export const INSTALLATIONS_CHANNEL_ACTIVATED_ROUTE = 'v1/installations/channel-activated';
export const INSTALLATION_BY_ID = 'v1/installations';
export const ROUTER_SHORTNAME = 'v1/installations/router-shortname';
export const INSTALLATION_DETAILS = 'v1/installations/last';
