import GirlIpad from 'assets/GirlIpad.svg';
import GridContent from 'components/GridContent';
import { INSTALLATIONS_PROCESS_STATUS } from 'constants/ApiRoutes';
import { ROUTE_CONCLUSION, ROUTE_INSTALLATIONS_ERROR, ROUTE_ONBOARDING } from 'constants/Routes';
import { SO_INSTALLATION, SO_INSTALLATION_STATUS, SO_INSTALLATION_TIMEOUT } from 'constants/TrackingEvents';
import { usePackContext } from 'contexts/PackContext';
import { useSelfOnboardingContext } from 'contexts/SelfOnboardingContext';
import { Step } from 'enums';
import { usePack } from 'hooks/usePack';
import { useTrack } from 'hooks/useTrack';
import { ILogArgs, logger } from 'packs-template-baseweb';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SaveQueue } from 'services/AttendanceQueueService';
import { createToastError, createToastSuccess, createToastWarning } from 'services/Toast';
import { saveResources } from 'services/saves/saveResouces';
import { ContainerProgressBar, ImageContentCenter } from 'styles';
import { StatusProcess } from 'typings/Installation';
import { handleApiErrorResponseForTrack, mountKey } from 'utils';

type ProgressBar = {
  start: boolean;
  percent: number;
};

const Installation: React.FC = () => {
  const logArgs: ILogArgs = {
    className: 'Installation',
  };

  const { apiService, setActiveStep, profile } = useSelfOnboardingContext();
  const { pack, setPack } = usePackContext();
  const installationIntervalRef = useRef<NodeJS.Timer>();
  const progressBarIntervalRef = useRef<NodeJS.Timer>();
  const [progressBar, setProgressBar] = useState<ProgressBar>({ start: true, percent: 0 });
  const installedRef = useRef<boolean>(false);
  const { track } = useTrack();
  const navigate = useNavigate();
  const { getInstallationDetails } = usePack();

  const installationFailureToast = () =>
    createToastError('Falha na preparação', 'Por favor, tente novamente daqui alguns minutos.');

  const getStatusByPercent = () => {
    if (progressBar.percent < 10) return 'aguarde enquanto preparamos seu pack...';
    if (progressBar.percent < 20) return 'separando os materiais...';
    if (progressBar.percent < 40) return 'organizando as ferramentas...';
    if (progressBar.percent < 60) return 'preparando o ambiente...';
    else return 'deixando tudo pronto...';
  };

  const getLoadingText = () => {
    const formattedPercent = progressBar.percent.toFixed(2).replace('.', ',');
    return `${formattedPercent}% concluído - ${getStatusByPercent()}`;
  };

  const handleTimeout = useCallback(() => {
    track(SO_INSTALLATION_TIMEOUT);
    createToastWarning(
      'Preparação pausada',
      'A preparação excedeu o tempo esperado. Tente continuar daqui alguns minutos.',
      12,
    );
  }, [track]);

  const createDefaultQueuesAndFaq = async () => {
    const installationData = await getInstallationDetails(pack.routerShortName ?? '');
    if (
      'deskShortName' in installationData &&
      'deskAccessKey' in installationData &&
      'routerShortName' in installationData &&
      'routerAccessKey' in installationData &&
      profile?.email
    ) {
      try {
        const deskKey = mountKey(installationData.deskShortName, installationData.deskAccessKey);
        const routerKey = mountKey(installationData.routerShortName, installationData.routerAccessKey);
        await saveResources({
          routerShortName: installationData.routerShortName,
          routerKey: routerKey,
          companyName: pack?.companyName,
        });
        await SaveQueue({
          emails: [profile?.email],
          deskKey,
          deskShortName: installationData.deskShortName,
          routerShortName: installationData.routerShortName,
          routerKey,
        });
        navigate(`${ROUTE_ONBOARDING}\\${ROUTE_CONCLUSION}`);
      } catch (error) {
        logArgs.methodName = 'createDefaultQueuesAndFaq';
        logger.error(`Error on save attendance resources: ${error}`, logArgs);
        createToastError('Falha ao salvar os recursos', 'Por favor, tente novamente daqui alguns minutos.');
        navigate(`${ROUTE_ONBOARDING}\\${ROUTE_INSTALLATIONS_ERROR}`);
      }
    } else {
      createToastError('Falha ao obter dados da instalação', 'Por favor, tente novamente.');
      navigate(`${ROUTE_ONBOARDING}\\${ROUTE_INSTALLATIONS_ERROR}`);
    }
  };

  const handleInstallationError = useCallback(() => {
    navigate(`${ROUTE_ONBOARDING}\\${ROUTE_INSTALLATIONS_ERROR}`);
  }, [navigate]);

  const completedInstallationPolling = async () => {
    try {
      const params = { applicationId: pack.id, installations: pack.installationId };
      const data = await apiService.get<StatusProcess[]>(`${INSTALLATIONS_PROCESS_STATUS}`, params);
      const installationStatusAlreadyExists = data.length > 0;

      if (installationStatusAlreadyExists) {
        if (data[0].status == 'Completed') {
          track(SO_INSTALLATION_STATUS, { status: 'Sucesso' });
          clearInterval(installationIntervalRef.current);
          installationIntervalRef.current = undefined;
          clearInterval(progressBarIntervalRef.current);
          progressBarIntervalRef.current = undefined;
          setProgressBar(prevState => ({ ...prevState, percent: 100 }));
          createToastSuccess('Preparação do Pack concluída com sucesso.');
          return await createDefaultQueuesAndFaq();
        } else if (data[0].status == 'Canceled') {
          track(SO_INSTALLATION_STATUS, { status: 'Falha', error: data[0].errorCode });
          setPack(pack => ({ ...pack, routerShortName: '', installationId: '' }));
          installationFailureToast();
          handleInstallationError();
          clearInterval(installationIntervalRef.current);
          installationIntervalRef.current = undefined;
        }

        if (!progressBarIntervalRef.current) {
          let currentProgress = 1;
          let delayDivider = 1;

          progressBarIntervalRef.current = setInterval(() => {
            currentProgress += (Math.random() * 1.7) / delayDivider;

            if (currentProgress >= 100) {
              setProgressBar(prevState => ({ ...prevState, percent: 100 }));
            } else {
              setProgressBar(prevState => ({ ...prevState, percent: currentProgress }));

              if (installedRef.current) {
                delayDivider = 0.25;
              } else {
                delayDivider += currentProgress > 60 ? 0.02 : 0.01;
              }
            }
          }, 500);
        }
      }
    } catch (error: any) {
      createToastError('Falha ao obter o status da preparação', 'Por favor, tente novamente daqui alguns minutos.');
      track(SO_INSTALLATION_STATUS, { status: 'Falha', error: handleApiErrorResponseForTrack(error) });
      clearInterval(installationIntervalRef.current);
      installationIntervalRef.current = undefined;
    }
  };

  useEffect(() => {
    setActiveStep(Step.Preparation);
    track(SO_INSTALLATION);
    if (pack.installed) {
      createDefaultQueuesAndFaq();
    } else if (pack.installationId) {
      setProgressBar({ start: true, percent: 0 });
      if (!installationIntervalRef.current) {
        const timeoutInMilliseconds = 1000 * 60 * 6;
        const intervalInMilliseconds = 5 * 1000;
        let totalTime = 0;

        installationIntervalRef.current = setInterval(() => {
          completedInstallationPolling();
          totalTime += intervalInMilliseconds;

          if (totalTime >= timeoutInMilliseconds) {
            handleTimeout();
          }
        }, intervalInMilliseconds);
      }
    } else {
      setProgressBar({ start: false, percent: 0 });
      handleInstallationError();
    }

    return () => {
      clearInterval(installationIntervalRef.current);
      installationIntervalRef.current = undefined;
      clearInterval(progressBarIntervalRef.current);
      progressBarIntervalRef.current = undefined;
    };
  }, []);

  return (
    <GridContent padding="3">
      <bds-grid sm="9" md="7" lg="9" xg="9" direction="column">
        <bds-typo tag="h4" variant="fs-32" bold="bold">
          Estamos criando a sua conta do Blip Go!
        </bds-typo>
        <bds-typo tag="h4" variant="fs-14">
          Este processo pode levar alguns minutos.
        </bds-typo>

        {progressBar.start && (
          <ContainerProgressBar>
            <bds-progress-bar percent={progressBar.percent} />
            <bds-typo variant="fs-12" bold="bold">
              {getLoadingText()}
            </bds-typo>
          </ContainerProgressBar>
        )}
      </bds-grid>

      <ImageContentCenter>
        <bds-grid sm="3" md="4" lg="3" xg="3">
          <img src={GirlIpad} alt="Pack Preparation" />
        </bds-grid>
      </ImageContentCenter>
    </GridContent>
  );
};

export default Installation;
