interface SetAttendantProps {
  attendantIdentity: string;
  teams: string[];
  guid: string;
}

export const SetAttendant = ({ attendantIdentity, teams, guid }: SetAttendantProps) => {
  return {
    id: guid,
    to: 'postmaster@desk.msging.net',
    method: 'set',
    uri: '/attendants',
    type: 'application/vnd.iris.desk.attendant+json',
    resource: {
      identity: attendantIdentity,
      teams,
    },
  };
};
