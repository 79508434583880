import styled from 'styled-components';

export const BannerContainer = styled.img`
  position: relative;
`;

export const ImageContainer = styled.div`
  display: flex;
  width: auto;
  height: 56px;
  justify-content: space-between;
  background-color: #cafcff;
  padding: 16px 16px;
`;
