import { usePackContext } from 'contexts/PackContext';
import { useSelfOnboardingContext } from 'contexts/SelfOnboardingContext';
import { Step } from 'enums';
import React from 'react';

const Stepper: React.FC = () => {
  const { activeStep } = useSelfOnboardingContext();
  const { pack } = usePackContext();
  const canRender = pack.tenant.id != '';

  return canRender ? (
    <bds-stepper>
      <bds-step
        active={activeStep === Step.Introduction}
        completed={Step.Introduction < activeStep}
        style={{ margin: '4px' }}
      >
        Início
      </bds-step>
      <bds-step
        active={activeStep === Step.Preparation}
        completed={Step.Preparation < activeStep}
        style={{ margin: '4px' }}
      >
        Criação do Blip Go!
      </bds-step>
      <bds-step
        active={activeStep === Step.Conclusion}
        completed={Step.Conclusion < activeStep}
        style={{ margin: '4px' }}
      >
        Conclusão
      </bds-step>
    </bds-stepper>
  ) : (
    <></>
  );
};

export default Stepper;
