import Spinner from 'components/Spinner';
import { usePack } from 'hooks/usePack';
import React, { FC, createContext, useEffect, useState } from 'react';
import { createToastError } from 'services/Toast';
import { Pack } from 'typings/Pack';
import { useSelfOnboardingContext } from './SelfOnboardingContext';

export type PackContextData = {
  pack: Pack;
  setPack: React.Dispatch<React.SetStateAction<Pack>>;
};

export const PackContext = createContext<PackContextData>({} as PackContextData);

export const PackProvider: FC = ({ children }) => {
  const { settings } = useSelfOnboardingContext();
  const isHomologation = settings.Environment == 'HMG' || settings.Environment == 'staging';
  const defaultPackId = isHomologation ? '430' : '60';
  const defaultTenant = isHomologation ? 'blipgo-hmg-interno' : 'blipatendimentotrial';
  const packId = process.env.REACT_APP_BLIP_GO_ID ? process.env.REACT_APP_BLIP_GO_ID : defaultPackId;
  const tenantId = process.env.REACT_APP_TENANT_ID ? process.env.REACT_APP_TENANT_ID : defaultTenant;
  const [pack, setPack] = useState<Pack>({ id: packId, installed: false, tenant: { id: tenantId } });
  const [initialized, setInitialized] = useState(false);
  const { getRouterShortName, getInstallationDetails } = usePack();

  const validateInstallation = async () => {
    try {
      const routerShortName = await getRouterShortName();
      if (routerShortName) {
        const installation = await getInstallationDetails(routerShortName);
        if ('statusProcess' in installation) {
          setPack(pack => ({
            ...pack,
            routerShortName,
            installationStatus: installation.statusProcess?.status,
            installationId: installation.installationId,
          }));
        }
      }
    } catch (error) {
      setPack(pack => ({ ...pack, installationStatus: 'InternalError' }));
      createToastError('Erro ao buscar a instalação', 'Por favor, tente novamente mais tarde.');
    } finally {
      setInitialized(true);
    }
  };

  useEffect(() => {
    validateInstallation();
  }, []);

  return !initialized ? <Spinner /> : <PackContext.Provider value={{ pack, setPack }}>{children}</PackContext.Provider>;
};

export function usePackContext(): PackContextData {
  const context = React.useContext(PackContext);

  if (!context) {
    throw new Error('use app context must be used within an AppProvider');
  }

  return context;
}
