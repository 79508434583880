import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    body {
        height: 100%;
        width: 100%;
        margin: 0;
        font-family: 'Nunito Sans';
        overflow-x: hidden;
      }

      * {
        margin: 0;
        padding: 0;
        border: 0;
        box-sizing: border-box;
      }

  bds-button[disabled="true"] {
    pointer-events: none;
  }
`;

export const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  color: #202c44;
`;

export const Center = styled.div`
  text-align: center;
`;

export const HelpLink = styled.a`
  color: #125ad5;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
`;

export const Flex = styled.div`
  display: flex;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerProgressBar = styled.div`
  margin: 24px 0;
`;

export const ImageContentCenter = styled.div`
  text-align: center;

  @media screen and (max-width: 700px) {
    display: none;
  }
`;
