export const initializeSegment = (writeKey: string) => {
  analytics.load(writeKey);
  analytics.page();
};

export const identify = (email: string) => {
  analytics.identify(email);
};

export const track = (event: string, payload?: any) => {
  analytics.track(event, payload);
};
