import BlipinhoSad from 'assets/BlipinhoSad.svg';
import GridContent from 'components/GridContent';
import { ROUTE_INSTALLATION, ROUTE_ONBOARDING } from 'constants/Routes';
import { SO_INSTALLATION_ERROR, SO_INSTALLATION_ERROR_BUTTON } from 'constants/TrackingEvents';
import { usePackContext } from 'contexts/PackContext';
import { usePack } from 'hooks/usePack';
import { useTrack } from 'hooks/useTrack';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InstallationService } from 'services/installation/InstallationService';
import { ImageContentCenter } from 'styles';
import { ContainerActions } from './styles';

const InstallationError: React.FC = () => {
  const { pack, setPack } = usePackContext();
  const { installPack } = usePack();
  const [isLoading, setIsLoading] = useState(false);
  const { track } = useTrack();
  const navigate = useNavigate();

  const installationService = new InstallationService();

  const handleClickInstallation = async () => {
    track(SO_INSTALLATION_ERROR_BUTTON);

    if (pack.installationId) {
      return navigate(`${ROUTE_ONBOARDING}\\${ROUTE_INSTALLATION}`);
    } else {
      await startInstallation();
    }
  };

  const startInstallation = async () => {
    if (!pack.companyName) {
      return navigate(ROUTE_ONBOARDING);
    }

    try {
      setIsLoading(true);
      const data = await installPack(pack.companyName);

      if (data) {
        const { routerShortName, installationId } = data;
        setPack(pack => ({ ...pack, routerShortName, installationId }));
        navigate(`${ROUTE_ONBOARDING}\\${ROUTE_INSTALLATION}`);
      }
    } catch (error: any) {
      installationService.installationException(error);
      const cleanCompanyName = installationService.cleanCompanyName(error);

      if (cleanCompanyName) {
        setPack(pack => ({ ...pack, companyName: '' }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(
    () =>
      track(SO_INSTALLATION_ERROR, {
        error: 'Não foi possivel fazer a instalação',
      }),
    [],
  );

  return (
    <GridContent padding="3" gap="4">
      <bds-grid md="9" lg="9" xg="9" direction="column" gap="4">
        <bds-typo tag="h4" variant="fs-32" bold="bold">
          Ops! Algo deu errado.
        </bds-typo>
        <bds-typo tag="h4" variant="fs-14">
          Aconteceu um problema técnico durante a criação do Blip Go!. Desculpe o inconveniente.
          <br />
          Por favor, tente novamente dentro de alguns instantes.
        </bds-typo>

        <ContainerActions>
          <bds-button variant="ghost" onClick={handleClickInstallation} bds-loading={isLoading}>
            Tentar Novamente
          </bds-button>
        </ContainerActions>
      </bds-grid>

      <ImageContentCenter>
        <bds-grid md="3" lg="3" xg="3">
          <img src={BlipinhoSad} alt="Pack Preparation" />
        </bds-grid>
      </ImageContentCenter>
    </GridContent>
  );
};

export default InstallationError;
