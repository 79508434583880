import Spinner from 'components/Spinner';
import { Step } from 'enums';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { ApiService } from 'services/Api';
import { Configuration } from 'typings/Configuration';
import { Profile } from 'typings/OIDCProfile';

export type SelfOnboardingContextData = {
  profile: Profile | undefined;
  settings: Configuration;
  apiService: ApiService;
  activeStep: Step;
  setActiveStep: React.Dispatch<React.SetStateAction<Step>>;
  skipActivation: boolean | undefined;
  setSkipActivation: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  utmParams: string | undefined;
  setUtmParams: React.Dispatch<React.SetStateAction<string | undefined>>;
  leadOrigin: string;
  setLeadOrigin: React.Dispatch<React.SetStateAction<string>>;
};

export interface SelfOnboardingContextValue {
  userdata: {
    access_token: string | undefined;
    profile: Profile | undefined;
    settings: Configuration;
  };
}

export const SelfOnboardingContext = createContext<SelfOnboardingContextData>({} as SelfOnboardingContextData);

export const SelfOnboardingProvider: React.FC<SelfOnboardingContextValue> = ({ children, userdata }) => {
  const [activeStep, setActiveStep] = useState<number>(Step.Introduction);
  const [settings, setSettings] = useState({} as Configuration);
  const [skipActivation, setSkipActivation] = useState<boolean | undefined>();
  const [initialized, setInitialized] = useState(false);
  const [utmParams, setUtmParams] = useState<string | undefined>();
  const [leadOrigin, setLeadOrigin] = useState<string>('');

  const apiService = useMemo(() => {
    if (Object.keys(settings).length > 0) {
      return new ApiService(settings.ApiServer, userdata.access_token);
    }
  }, [settings, userdata]);

  useEffect(() => {
    if (userdata.settings) {
      setSettings(userdata.settings);
    }
  }, [userdata]);

  useEffect(() => {
    const urlParams = new URLSearchParams();
    new URLSearchParams(window.location.search).forEach((value, key) => {
      urlParams.append(key.toLowerCase(), decodeURI(value));
    });

    const skipActivationUrl = urlParams.get('skipactivation');

    if (!!skipActivationUrl) {
      setSkipActivation(skipActivationUrl == 'true');
    }
    setInitialized(true);
    const sourceUtm = localStorage.getItem('source');
    if (sourceUtm) {
      setUtmParams(sourceUtm);
    }
  }, []);

  return apiService && initialized ? (
    <>
      <SelfOnboardingContext.Provider
        value={{
          settings,
          apiService,
          profile: userdata.profile,
          activeStep,
          setActiveStep,
          skipActivation,
          setSkipActivation,
          utmParams,
          setUtmParams,
          leadOrigin,
          setLeadOrigin,
        }}
      >
        {children}
      </SelfOnboardingContext.Provider>
    </>
  ) : (
    <Spinner />
  );
};

export function useSelfOnboardingContext(): SelfOnboardingContextData {
  const context = React.useContext(SelfOnboardingContext);

  if (!context) {
    throw new Error('use app context must be used within an AppProvider');
  }

  return context;
}
