export const SO_OPENED = 'blippacks-selfonboarding-opened';
export const SO_API_CALL = 'blippacks-selfonboarding-apicall';
export const SO_NEXT = 'blippacks-selfonboarding-next';
export const SO_CHECKLIST_Q1 = 'blippacks-selfonboarding-checklist-first-question-next';
export const SO_CHECKLIST_Q2 = 'blippacks-selfonboarding-checklist-second-question-next';
export const SO_CHECKLIST_Q3 = 'blippacks-selfonboarding-checklist-third-question-next';
export const SO_CHECKLIST_Q4 = 'blippacks-selfonboarding-checklist-fourth-question-next';
export const SO_EMB_SIGNUP_INTERRUPTED = 'blippacks-selfonboarding-embeddedsingup-interrupted';
export const SO_HELP = 'blippacks-selfonboarding-help';
export const SO_HELP_ACTIVATION = 'blippacks-selfonboarding-help-activation';
export const SO_RISKS = 'blippacks-selfonboarding-risks';
export const SO_STEPBACK = 'blippacks-selfonboarding-stepback';
export const SO_CONTINUE = 'blippacks-selfonboarding-continue';
export const SO_LINK = 'blippacks-selfonboarding-link';
export const SO_ACTIVATED = 'blippacks-selfonboarding-activated';
export const SO_START_ACTIVATION = 'blippacks-selfonboarding-startactivation';
export const SO_EMBEDDED_SIGNUP = 'blippacks-selfonboarding-loginFB';
export const SO_OPEN_EMBEDDED_SIGNUP = 'blippacks-selfonboarding-facebook-loginFB';
export const SO_EMBEDDED_SIGNUP_STATUS = 'blippacks-selfonboarding-facebook-loginFB-status';
export const SO_STEPBACK_ATTENTION = 'blippacks-selfonboarding-stepback-attention';
export const SO_ACTIVATION_BUTTON = 'blippacks-selfonboarding-activationbutton';
export const SO_WAITING_WPP_ACTIVATION = 'blippacks-selfonboarding-waiting-wpp-activation';
export const SO_WHATSAPP_CONFIGURATED = 'blippacks-selfonboarding-waconfigurated';
export const SO_WHATSAPP_ACTIVATED = 'blippacks-selfonboarding-channelactivated';
export const SO_WHATSAPP_ACTIVATION_ERROR = 'blippacks-selfonboarding-channelactivatederror';
export const SO_WHATSAPP_ACTIVATED_NEXT = 'blippacks-selfonboarding-channelactivated-next';
export const SO_FINISHED = 'blippacks-selfonboarding-finished';
export const SO_SESSION_RECOVERY = 'blippacks-selfonboarding-session-recovery';
export const SO_TENANT_SELECTED = 'blippacks-selfonboarding-tenant-selection';
export const SO_WHATSAPP_CONFIGURATION = 'blippacks-selfonboarding-editsettingsscreen';
export const SO_API_CALL_ERROR = 'blippacks-selfonboarding-apicall-error';
export const SO_INSTALLATION_ERROR = 'blippacks-selfonboarding-installation-errorscreen-blipgo';
export const SO_INSTALLATION_ERROR_BUTTON = 'blippacks-selfonboarding-installation-error-tryagain-blipgo';
export const SO_INSTALLATION = 'blippacks-selfonboarding-installation';
export const SO_INSTALLATION_BUTTON = 'blippacks-selfonboarding-installation-button';
export const SO_INSTALLATION_STATUS = 'blippacks-selfonboarding-installation-status';
export const SO_START_WHATSAPP_ACTIVATION = 'blippacks-selfonboarding-activate-wpp';
export const SO_START_WHATSAPP_ACTIVATION_BUTTON = 'blippacks-selfonboarding-activate-wpp-button';
export const SO_ACTION_ALERT_COMPANY_NAME = 'blippacks-selfonboarding-attention_company_name';
export const SO_HUBSPOT_TICKET_CREATION = 'blippacks-selfonboarding-hubspot-ticket-created';
export const SO_CONCLUSION_AUTO_REDIRECT = 'blippacks-selfonboarding-redirect-to-pack-manager';
export const SO_CONCLUSION_LINK_REDIRECT = 'blippacks-selfonboarding-click-redirect-to-pack-manager';
export const SO_EXISTING_INSTALLATIONS_LINK_REDIRECT = 'blippacks-selfonboarding-click-redirect-to-pack-manager';
export const SO_OPEN_EXISTING_INSTALLATIONS_PAGE = 'blippacks-managment-register-verification';
export const SO_OPEN_LOADING_PAGE = 'blippacks-managment-loading';
export const SO_INSTALLATION_TIMEOUT = 'blippacks-selfonboarding-installation-timeout';
export const ANALYTICS_KEY_HMG = 'd9pdZmq8Zir53Nrpm9X2seash4bPUUpt';
export const ANALYTICS_KEY_PRD = 'c54ZPJr9H48j4Js23n8ZiRLqJHiwjBjt';
export const SO_FILTER = 'blipapps-selfonboarding-filter';
export const SO_ATTENDANT = 'blippacks-selfonboarding-attendant';
export const SO_ATTENDANT_INVITE_LATER = 'blippacks-selfonboarding-attendant-invite-later';
export const SO_ATTENDANT_REMOVE = 'blippacks-selfonboarding-attendant-remove';
export const SO_ATTENDANT_ADD = 'blippacks-selfonboarding-attendant-add';
export const BLIPGO_LINK_OPENED = 'blippacks-blipgo-linkopened';
