import { ILogArgs, json, logger } from 'packs-template-baseweb';

export class EclusterRequets {
  private api: any;
  private logArgs: ILogArgs;

  constructor(api: any) {
    this.api = api;
    this.logArgs = {
      className: 'EclusterRequets',
    };
  }

  public async GetDirectionLeadByEmail(email: any, isFatal: boolean = false) {
    let response: any;
    try {
      response = await this.api.get(`/directionlead/email/${email}`);

      if (response.data.status === 'failure') {
        throw new Error(response.data);
      }

      return response.data;
    } catch (error) {
      const message = `Problem on doing the following request to ECluster API 
        | api: '${json.format(this.api.defaults)}' 
        | data: '${json.format(email)}' 
        | response: '${json.format(response)}' 
        | error: '${json.format(error)}`;

      this.logArgs.methodName = 'GetDirectionLeadByEmail';

      if (isFatal) logger.fatal(message, this.logArgs);
      else logger.error(message, this.logArgs);
    }
  }

  public async GetDirectionLeadByInstallationId(installationId: any, isFatal: boolean = false) {
    let response: any;
    try {
      response = await this.api.get(`/directionlead/installation/${installationId}`);

      if (response.data.status === 'failure') {
        throw new Error(response.data);
      }

      return response.data;
    } catch (error) {
      const message = `Problem on doing the following request to ECluster API 
        | api: '${json.format(this.api.defaults)}' 
        | data: '${json.format(installationId)}' 
        | response: '${json.format(response)}' 
        | error: '${json.format(error)}`;

      this.logArgs.methodName = 'GetDirectionLeadByInstallationId';

      if (isFatal) logger.fatal(message, this.logArgs);
      else logger.error(message, this.logArgs);
    }
  }

  public async CreateDirectionLead(data: any, isFatal: boolean = false) {
    let response: any;
    try {
      response = await this.api.post('/directionlead', data);

      if (response.data.status === 'failure') {
        throw new Error(response.data);
      }

      return response.data;
    } catch (error) {
      const message = `Problem on doing the following request to ECluster API 
        | api: '${json.format(this.api.defaults)}' 
        | data: '${json.format(data)}' 
        | response: '${json.format(response)}' 
        | error: '${json.format(error)}`;

      this.logArgs.methodName = 'CreateDirectionLead';

      if (isFatal) logger.fatal(message, this.logArgs);
      else logger.error(message, this.logArgs);
      throw new Error(`${json.format(error)}`);
    }
  }
}
