import { INITIALIZATION_TIMEOUT } from 'constants/GeneralConstants';
import { getOidcBaseConfig } from 'constants/Oidc';
import { ROUTE_ONBOARDING } from 'constants/Routes';
import { ANALYTICS_KEY_HMG, ANALYTICS_KEY_PRD, BLIPGO_LINK_OPENED } from 'constants/TrackingEvents';
import { LeadOrigin } from 'constants/UpdateContactHubspot';
import { useTrack } from 'hooks/useTrack';
import { AuthProvider } from 'oidc-react';
import { logger } from 'packs-template-baseweb';
import EmptyRoute from 'pages/EmptyRoute';
import MainContainer from 'pages/MainContainer';
import SelfOnboarding from 'pages/SelfOnboarding';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { initializeSegment } from 'services/Segment';
import { getUTMParametersFromLocation } from 'utils/queryParams';
import './font_face.scss';

logger.configure();

const App: React.FC = () => {
  const sourceUtm = getUTMParametersFromLocation();

  const getUrlRedirect = () => {
    let urlRedirect = window.location.origin + ROUTE_ONBOARDING + '?&journey_simple=1';
    const leadOriginParamInSourceUtm = sourceUtm?.[LeadOrigin.QueryParam];
    if (leadOriginParamInSourceUtm) {
      urlRedirect += `&${LeadOrigin.QueryParam}=` + leadOriginParamInSourceUtm;
    }

    return urlRedirect;
  };

  const oidc = getOidcBaseConfig(getUrlRedirect());
  const [, setUtmParams] = useState<Record<string, string> | null>(null);
  const [isAppReady, setIsAppReady] = useState(false);
  const { track } = useTrack();

  const isNonAuthAccess = ['/'].includes(window.location.pathname);

  useEffect(() => {
    process.env.REACT_APP_ENV === 'production'
      ? initializeSegment(ANALYTICS_KEY_PRD)
      : initializeSegment(ANALYTICS_KEY_HMG);

    setTimeout(() => {
      setIsAppReady(true);
    }, INITIALIZATION_TIMEOUT);

    trackLinkOpened();
  }, []);

  const trackLinkOpened = () => {
    const utmParams = getUTMParametersFromLocation();

    if (utmParams) {
      track(BLIPGO_LINK_OPENED, { source: utmParams }, () => {
        setIsAppReady(true);
      });
    }

    if (sourceUtm && !localStorage.getItem('source')) {
      setUtmParams(sourceUtm);
      localStorage.setItem('source', JSON.stringify(sourceUtm));
    }
  };

  const routesWithAuthProvider = () => (
    <AuthProvider {...oidc}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainContainer />}>
            <Route index element={<EmptyRoute />} />
            <Route path="onboarding/*" element={<SelfOnboarding />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );

  return isAppReady ? (
    <bds-theme-provider theme="light">
      {isNonAuthAccess ? (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<EmptyRoute />}></Route>
          </Routes>
        </BrowserRouter>
      ) : (
        routesWithAuthProvider()
      )}
    </bds-theme-provider>
  ) : (
    <bds-loading-page></bds-loading-page>
  );
};

export default App;
