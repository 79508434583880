import { CurrentEnvironment } from './../configs/Environment';
export const Environment = {
  Development: 'DEV',
  Staging: 'HMG',
  Production: 'PRD',
};

function IsDevelopment(): boolean {
  return CurrentEnvironment === Environment.Development;
}

function IsStaging(): boolean {
  return CurrentEnvironment === Environment.Staging;
}

function IsProduction(): boolean {
  return CurrentEnvironment === Environment.Production;
}

export const Environments = {
  IsDevelopment,
  IsStaging,
  IsProduction,
};
