import { Pack } from 'typings/Pack';
import { Profile } from 'typings/OIDCProfile';
import { ApiService } from 'services/Api';
import { INSTALL_CHATBOT_ROUTE } from 'constants/ApiRoutes';
import { createToastWarning, createToastError } from 'services/Toast';
import { track } from 'services/Segment';
import { SO_INSTALLATION_STATUS } from 'constants/TrackingEvents';
import { handleApiErrorResponseForTrack } from 'utils';

type StartInstallation = {
  routerShortName?: string;
  installationId?: string;
};

export class InstallationService {
  botInstallation = async (pack: Pack, profile: Profile | undefined, apiService: ApiService) => {
    const body = {
      applicationId: pack.id,
      clientIdentity: pack.companyName,
      clientEmail: profile?.Email,
      tenantId: pack.tenant.id,
      tenantName: pack.tenant.name,
    };

    return await apiService.post<StartInstallation>(INSTALL_CHATBOT_ROUTE, body);
  };

  installationException = (error: any) => {
    const errorCode = error?.response?.data?.Title;

    switch (errorCode) {
      case 'CLIENT_NAME_ALREADY_EXISTS_IN_PACK_INSTALLATION':
        createToastWarning('Nome da empresa já existe', 'O nome não está disponível mais. Por favor, informe outro.');
        track(SO_INSTALLATION_STATUS, { status: 'Falha', error: 'Nome da empresa já existe' });
        break;

      default:
        track(SO_INSTALLATION_STATUS, { status: 'Falha', error: handleApiErrorResponseForTrack(error) });
        createToastError('Falha na preparação', 'Por favor, tente novamente daqui alguns minutos.');
        break;
    }
  };

  cleanCompanyName = (error: any) => {
    const errorCode = error?.response?.data?.Title;
    let cleanCompanyName = false;

    switch (errorCode) {
      case 'CLIENT_NAME_ALREADY_EXISTS_IN_PACK_INSTALLATION':
        cleanCompanyName = true;
        break;
    }

    return cleanCompanyName;
  };
}
