import axios from 'axios';
import { ILogArgs, json, logger } from 'packs-template-baseweb';

interface IApiAcceptTerms {
  data: { errors: string[]; result: string; isSuccess: boolean };
}

const logArgs: ILogArgs = {
  className: 'ApiAcceptTerms',
};

interface IApiAcceptTermsRequest {
  merchantName: string;
  email: string;
  packId: number;
  termId: number;
  acceptAtDate: Date;
}

export const postAcceptTermsApi = async (params: IApiAcceptTermsRequest): Promise<IApiAcceptTerms | undefined> => {
  logArgs.methodName = 'postAcceptTermsApi';

  const route = process.env.REACT_APP_URL_API_ACCEPT_TERMS as string;
  let resp: any;

  try {
    resp = await axios.post(route, {
      ...params,
    });
    return resp;
  } catch (error) {
    logger.fatal(
      `Error on doing patch on '${route}': 
    | response: ${json.format(resp)}
    | api: ${json.format(axios.defaults)}
    | error: ${json.format(error)}`,
      logArgs,
    );
  }
};

export async function saveTermsApiAccept(
  merchantName: string,
  email: string,
  packId: number,
  termId: number,
  acceptAtDate: Date,
) {
  const result = await postAcceptTermsApi({
    merchantName,
    email,
    packId,
    termId,
    acceptAtDate,
  });
  return result;
}
