import { useEffect } from 'react';
import { PluginEvents as ModuleEvents } from 'services/EventExecuter';
import { EventData } from 'typings/EventData';

type RegisteredEventsOpts = {
  [key: string]: (event: Event) => any;
};

export const usePluginEventsHandler = () => {
  useEffect(() => {
    const registeredEvents: RegisteredEventsOpts = {};

    for (const key in ModuleEvents) {
      if (Object.prototype.hasOwnProperty.call(ModuleEvents, key)) {
        const callback = ModuleEvents[key];
        const eventHandler = (event: Event) => {
          const customEvent = event as CustomEvent;
          const data = customEvent.detail as EventData;
          const result = callback(data);

          window.dispatchEvent(
            new CustomEvent(data.eventId, { detail: new EventData(data.eventId, data.eventName, result) }),
          );
        };

        registeredEvents[key] = eventHandler;
        window.addEventListener(key, eventHandler);
      }
    }

    return () => {
      for (const key in registeredEvents) {
        if (Object.prototype.hasOwnProperty.call(registeredEvents, key)) {
          const eventHandler = registeredEvents[key];
          window.removeEventListener(key, eventHandler);
        }
      }
    };
  }, []);
};
