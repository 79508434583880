import axios from 'axios';

export const api = (user: string, password: string) => {
  const url = process.env.REACT_APP_EXPERIENCE_CLUSTER_API_URL ?? '';

  return axios.create({
    baseURL: url,
    auth: {
      username: user,
      password: password,
    },
  });
};
