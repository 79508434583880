import blipColors from 'blip-tokens/build/json/colors.json';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  img {
    margin-right: 24px;
  }

  bds-typo {
    text-align: center;
    color: ${blipColors['color-extend-grays-street']};
  }
`;

export const Background = styled.footer<{ isContentScrollable: boolean }>`
  padding: 1rem;
  background-color: ${blipColors['color-neutral-light-whisper']};
  width: 100%;

  position: ${({ isContentScrollable }) => (isContentScrollable ? 'relative' : 'fixed')};
  bottom: 0;
`;
