import Banners from 'assets/Banners';
import BlipGoLogo from 'assets/BlipGoLogo.svg';
import Image from 'components/Image';
import { ROUTE_PACK_NOT_FOUND } from 'constants/Routes';
import { useSelfOnboardingContext } from 'contexts/SelfOnboardingContext';
import { Step } from 'enums';
import React from 'react';
import { Container } from './styles';

const Banner: React.FC = () => {
  const { activeStep } = useSelfOnboardingContext();

  return (
    <Container>
      {activeStep === Step.Introduction && location.pathname != ROUTE_PACK_NOT_FOUND ? (
        <Image srcImage={Banners.BlipGo} srcSmallImage={BlipGoLogo} addRectangle={true} />
      ) : (
        <Image srcImage={Banners.BlipGoSmall} srcSmallImage={BlipGoLogo} />
      )}
    </Container>
  );
};

export default Banner;
