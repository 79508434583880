export enum Step {
  Introduction = 1,
  Preparation = 2,
  AttendanceTeam = 3,
  Conclusion = 4,
  TestYourPack = 5,
}

export enum Plan {
  Standard = 'standard',
  Enterprise = 'enterprise',
  Lite = 'lite',
}

export enum PackApprovalStatus {
  Pending,
  Approved,
  Disapproved,
}
