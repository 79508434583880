import { getOidcBaseConfig } from 'constants/Oidc';
import { initUserManager } from 'oidc-react';

export const LogOut = () => {
  const urlRedirect = window.location.href;
  const oidc = getOidcBaseConfig(urlRedirect);
  const userManager = initUserManager(oidc);

  userManager.removeUser().then(() => {
    const urlEncoded = encodeURI(urlRedirect);
    const urlLogout = `${process.env.REACT_APP_AUTHORITY}/logout/redirect?returnDesiredUrl=${urlEncoded}`;
    window.location.href = urlLogout;
  });
};
