import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { MenuStructure } from 'typings/MenuStructure';

type AppContextValue = {
  menuStructure: MenuStructure;
  setMenuStructure: Dispatch<SetStateAction<MenuStructure>>;
};

export const AppContext = createContext<AppContextValue>({} as AppContextValue);

export const AppProvider: React.FC = ({ children }) => {
  const [menuStructure, setMenuStructure] = useState<MenuStructure>([]);

  return <AppContext.Provider value={{ menuStructure, setMenuStructure }}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
