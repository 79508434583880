import { usePackContext } from 'contexts/PackContext';
import { useSelfOnboardingContext } from 'contexts/SelfOnboardingContext';
import { useCallback } from 'react';
import { getDeviceType } from 'utils';

export type TrackProps = {
  screenName?: string;
  buttonName?: string;
  linkName?: string;
  userOption?: string;
  statusButton?: string;
  status?: string;
  lastPageUrl?: string;
  currentPageUrl?: string;
  error?: string;
  questionAnswer?: string;
  packId?: string;
  tenant?: string;
  plan?: string;
  routerShortName?: string;
  merchantName?: string;
  installationId?: string;
  action?: string;
  attendantsNumber?: number;
  source?: object;
};

export const useTrack = () => {
  const { skipActivation } = useSelfOnboardingContext();
  const { pack } = usePackContext();

  const createPayload = useCallback(
    (trackProps?: TrackProps) => {
      const packId = trackProps?.packId ?? pack?.id;
      const tenantId = trackProps?.tenant ?? pack?.tenant?.id;
      const planName = trackProps?.plan ?? pack?.tenant?.plan?.name;
      const routerShortName = trackProps?.routerShortName ?? pack?.routerShortName;
      const merchantName = trackProps?.merchantName ?? pack?.companyName;
      const installationId = trackProps?.installationId ?? pack?.installationId;
      const ownerSO = 'november';

      const payload = {
        merchantName: merchantName ?? '',
        packId: packId ?? '',
        tenant: tenantId,
        plan: planName ?? '',
        botIdentifier: routerShortName ?? '',
        activationOption: false,
        deviceType: getDeviceType(),
        operationalSystem: navigator.userAgentData?.platform ?? '',
        screenResolution: `${screen.width}x${screen.height}`,
        installationId: installationId ?? '',
        skipActivation: skipActivation,
        ownerSO: ownerSO,
      };

      return { ...trackProps, ...payload };
    },
    [
      pack?.companyName,
      pack?.id,
      pack?.installationId,
      pack?.routerShortName,
      pack?.tenant?.id,
      pack?.tenant?.plan?.name,
      skipActivation,
    ],
  );

  const track = useCallback(
    (event: string, trackProps?: TrackProps, callback?: () => void) => {
      const payload = createPayload(trackProps);
      analytics.track(event, payload, {}, callback);
    },
    [createPayload],
  );

  return { track };
};
