import React, { useEffect, useRef } from 'react';

type CheckboxProps = {
  onChange: (e: Event) => void;
  checked?: any;
  label?: string;
  name?: string;
  disabled?: boolean;
};

export const Checkbox = ({ onChange, checked, label, name, disabled }: CheckboxProps) => {
  const checkboxRef = useRef<HTMLBdsCheckboxElement>(null);

  useEffect(() => {
    if (checkboxRef.current != null) {
      checkboxRef.current.checked = checked;
      checkboxRef.current.addEventListener('bdsChange', event => {
        onChange(event);
      });
    }
  }, [checked, onChange]);

  return (
    <bds-checkbox
      name={name as string}
      ref={checkboxRef}
      checked={checked}
      label={label as string}
      disabled={disabled}
      refer=""
      style={{ display: 'flex', alignSelf: 'center' }}
    />
  );
};
