import { Table } from 'typings/Table';
import BlipGo from './BlipGo.svg';
import BlipGoSmall from './BlipGoSmall.svg';
import BlipPacks from './BlipPacks.svg';
import BlipPacksSmall from './BlipPacksSmall.svg';

const Banners: Table = {
  BlipPacks,
  BlipPacksSmall,
  BlipGo,
  BlipGoSmall,
};

export default Banners;
