import Blipsales from 'assets/Blipsales.svg';
import colors from 'blip-tokens/build/json/colors.json';
import Checkbox from 'components/Checkbox';
import ConfirmAccountModal from 'components/ConfirmAccountModal';
import GridContent from 'components/GridContent';
import { ROUTE_INSTALLATION } from 'constants/Routes';
import { SO_ACTION_ALERT_COMPANY_NAME, SO_API_CALL_ERROR, SO_NEXT, SO_OPENED } from 'constants/TrackingEvents';
import { LeadOrigin, UpdateContactHubspot } from 'constants/UpdateContactHubspot';
import { usePackContext } from 'contexts/PackContext';
import { useSelfOnboardingContext } from 'contexts/SelfOnboardingContext';
import { Step } from 'enums';
import { usePack } from 'hooks/usePack';
import { useTrack } from 'hooks/useTrack';
import { useAuth } from 'oidc-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleUpdateContactHubspot } from 'services/HubspotIntegration';
import { createToastError } from 'services/Toast';
import { postAcceptTermsApi } from 'services/acceptTerms/ApiAcceptTerms';
import { ImageContentCenter } from 'styles';
import { handleApiErrorResponseForTrack } from 'utils';
import { getUTMParametersFromLocation } from 'utils/queryParams';
import { CenteredContainer, ContainerActions, ContainerInputLabel, FullWidthContainer } from './styles';

type companyNameState = {
  value: string;
  messageError?: string;
  hasError?: boolean;
};

const Welcome: React.FC = () => {
  const auth = useAuth();
  const companyfromAccount = auth.userData?.profile.Company;
  const { installPack } = usePack();
  const { apiService, profile, setActiveStep, setLeadOrigin } = useSelfOnboardingContext();
  const { pack, setPack } = usePackContext();
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { track } = useTrack();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenConfirmAccountModal, setIsOpenConfirmAccountModal] = useState(false);
  const [companyName, setCompanyName] = useState<companyNameState>({
    hasError: false,
    messageError: '',
    value: '',
  });
  const companyNameRef = React.useRef<HTMLBdsInputElement>(null);
  const navigate = useNavigate();

  const handleChangeCompanyName = (e: any) => {
    validateCompanyName(e.target.value);
  };

  const validateCompanyName = (name: string) => {
    const regexValidName = /^[a-zA-Z].*/g;
    if (!name.match(regexValidName)) {
      setCompanyName({
        value: name,
        hasError: true,
        messageError: 'O nome da empresa não pode começar com número e caracteres especiais.',
      });
    } else {
      setCompanyName({
        value: name,
        hasError: false,
        messageError: '',
      });
    }
  };

  const disabledNextButton: boolean =
    companyName.value.trim().length > 1 &&
    companyName.value.trim().length <= 100 &&
    !isLoading &&
    termsAccepted &&
    !companyName.hasError;

  const handleCompanyNameResult = () => {
    track(SO_ACTION_ALERT_COMPANY_NAME, {
      merchantName: companyName.value.trim(),
      action: 'Continuar',
    });

    setPack({ ...pack, companyName: companyName.value.trim() });
    const date = new Date();

    if (profile?.email?.includes('@blip.ai')) {
      return {
        data: {
          isSuccess: true,
        },
      };
    } else {
      const result = postAcceptTermsApi({
        acceptAtDate: date,
        email: profile?.email as string,
        merchantName: profile?.FullName as string,
        termId: 1,
        packId: Number(pack?.id),
      });

      return result;
    }
  };

  const handleTrackNext = (status: string, error?: string) => {
    track(SO_NEXT, {
      screenName: 'Início',
      buttonName: 'Próximo',
      status: status,
      error: error ?? '',
      merchantName: companyName.value.trim(),
    });
  };

  const handleNextValidate = () => {
    if (disabledNextButton) {
      handleNext();
    }
  };

  const handleNext = async () => {
    try {
      setIsLoading(true);
      handleTrackNext('Sucesso');
      const result = await handleCompanyNameResult();
      if (result?.data.isSuccess) {
        setPack({ ...pack, companyName: companyName.value.trim() });
        await startInstallation();
      } else {
        createToastError('Falha ao aceitar os termos', 'Por favor, tente novamente daqui alguns minutos.');
      }
    } catch (error) {
      handleTrackNext('Falha', handleApiErrorResponseForTrack(error));
      createToastError('Falha ao processar as informações', 'Por favor, tente novamente daqui alguns minutos.');
    }

    setIsLoading(false);
  };

  const startInstallation = async () => {
    try {
      const data = await installPack(companyName.value.trim());
      if (data) {
        const { routerShortName, installationId } = data;
        setPack(pack => ({ ...pack, routerShortName, installationId }));
        navigate(ROUTE_INSTALLATION);
      }
      return data;
    } catch (error: any) {
      track(SO_API_CALL_ERROR, { error: error });
      createToastError('Falha ao criar a instalação', error);
    }
  };

  const handleChangeTermsAccepted = (e: any) => {
    const value = e.target.checked;
    setTermsAccepted(value);
  };

  const handleClickTermsText = () => {
    setTermsAccepted(termsAccepted => !termsAccepted);
  };

  useEffect(() => {
    setActiveStep(Step.Introduction);
    setPack(pack => ({ ...pack, companyName: '' }));
    track(SO_OPENED);

    updateLeadStatusHubspot();
    updateLeadOriginHubspot();
  }, []);

  const updateLeadStatusHubspot = async () => {
    await handleUpdateContactHubspot(
      [
        {
          property: UpdateContactHubspot.Status,
          value: UpdateContactHubspot.ContaCriada,
        },
      ],
      auth.userData?.access_token ?? '',
    );
  };

  const updateLeadOriginHubspot = () => {
    const sourceUtm = getUTMParametersFromLocation();
    const leadOriginQueryParam = sourceUtm?.[LeadOrigin.QueryParam];

    if (!leadOriginQueryParam) return;

    const leadOriginParam = leadOriginQueryParam.toLocaleLowerCase();

    setLeadOrigin(leadOriginParam);
  };

  useEffect(() => {
    if (profile?.EmailConfirmed.toLowerCase() !== 'true') {
      setIsOpenConfirmAccountModal(true);
    }

    if (companyfromAccount) {
      validateCompanyName(companyfromAccount);
    } else {
      validateCompanyName('');
    }
  }, []);

  return (
    <GridContent>
      <bds-grid sm="9" md="7" lg="9" xg="9" direction="column">
        <bds-typo tag="h4" variant="fs-32" bold="bold">
          Vamos lá?
        </bds-typo>
        <bds-typo tag="h4" variant="fs-16">
          Iniciaremos o processo de criação e ativação da sua conta Blip Go! Em breve, você terá uma plataforma para
          criar e gerenciar o chatbot do seu atendimento.
        </bds-typo>
        <br />

        <ContainerInputLabel>
          <bds-typo variant="fs-14">Nome da empresa</bds-typo>
          <bds-tooltip position="right-center" tooltip-text="O nome da sua filial, matriz, apelido ou unidade.">
            <bds-icon
              color={colors['color-extend-grays-street']}
              name="info"
              size="x-small"
              theme="solid"
              aria-label="Ícone de informações"
            ></bds-icon>
          </bds-tooltip>
        </ContainerInputLabel>

        <div>
          <bds-input
            ref={companyNameRef}
            icon="company"
            placeholder="Ex.: Blip unidade BH"
            counter-length
            minlength={2}
            minlength-error-message="Este campo deve ter no mínimo 2 caracteres."
            maxlength={100}
            required
            required-error-message="Campo obrigatório."
            onInput={handleChangeCompanyName}
            value={companyName.value}
            error-message={companyName.value == '' ? 'Campo obrigatório.' : companyName.messageError}
            danger={companyName.hasError}
          />
        </div>

        <FullWidthContainer>
          <Checkbox onChange={handleChangeTermsAccepted} checked={termsAccepted} />
          <CenteredContainer>
            <bds-typo variant="fs-16" tag="p" onClick={handleClickTermsText} style={{ cursor: 'pointer' }}>
              &nbsp;Declaro que li e estou de acordo com os&nbsp;
            </bds-typo>
            <a href="https://www.blip.ai/docs/termos-de-uso-blip-go/" target="_blank" rel="noreferrer">
              Termos de Uso
            </a>
            .
          </CenteredContainer>
        </FullWidthContainer>

        <ContainerActions>
          <bds-button arrow={true} disabled={!disabledNextButton} onClick={handleNextValidate} bds-loading={isLoading}>
            Próximo
          </bds-button>
        </ContainerActions>
      </bds-grid>

      <ImageContentCenter>
        <bds-grid sm="3" md="4" lg="3" xg="3">
          <img src={Blipsales} alt="Blip sales" />
        </bds-grid>
      </ImageContentCenter>

      <ConfirmAccountModal isOpen={isOpenConfirmAccountModal} />
    </GridContent>
  );
};

export default Welcome;
