interface SetAttendanceRuleProps {
  ownerIdentity: string;
  queueId: string;
  queueName: string;
  guid: string;
}

export const SetAttendanceRule = ({ ownerIdentity, queueId, queueName, guid }: SetAttendanceRuleProps) => {
  return {
    id: guid,
    to: 'postmaster@desk.msging.net',
    method: 'set',
    uri: '/rules',
    type: 'application/vnd.iris.desk.rule+json',
    resource: {
      id: guid,
      isActive: true,
      opened: true,
      operator: 'Or',
      ownerIdentity,
      queueId,
      repeatConditionsBody: false,
      team: queueName,
      title: 'Attendance Rule',
      valuesEmpty: false,
      conditions: [
        {
          extrasProperty: 'teams',
          property: 'Contact.Extras.teams',
          relation: 'Contains',
          values: [queueName],
        },
      ],
    },
  };
};
