import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { PackProvider } from 'contexts/PackContext';
import { SelfOnboardingProvider } from 'contexts/SelfOnboardingContext';
import { useHashPrevention } from 'hooks/useHashPrevention';
import { Profile } from 'oidc-client';
import { useAuth } from 'oidc-react';
import { useEffect, useState } from 'react';
import { Configuration } from 'typings/Configuration';
import Steps from '../Steps';

interface BaseUserData {
  access_token: string | undefined;
  profile: Profile | undefined;
  settings: Configuration;
}

const SelfOnboarding: React.FC = () => {
  const auth = useAuth();
  const [appData, setAppData] = useState<BaseUserData>({} as BaseUserData);
  useHashPrevention('/onboarding');

  useEffect(() => {
    document.title = 'Blip Packs - Onboarding';

    const userData = {
      access_token: auth.userData?.access_token,
      profile: auth.userData?.profile,
      settings: {
        ApiServer: process.env.REACT_APP_API_SERVER ?? '',
        ApiPort: process.env.REACT_APP_API_PORT ?? '',
        BlipLearnId: 47,
        BlipGoIdHmg: Number(process.env.REACT_APP_BLIP_GO_ID),
        BlipGoId: Number(process.env.REACT_APP_BLIP_GO_ID),
        Environment: process.env.REACT_APP_ENVIRONMENT ?? '',
        HubspotApiKey: process.env.REACT_APP_HUBSPOT_API_KEY ?? '',
        InstallationChatBot: process.env.REACT_APP_INSTALLATION_CHAT_BOT ?? '',
        NumberDaysToExpireUserSession: Number(process.env.REACT_APP_NUMBER_DAYS_TO_EXPIRE_USER_SESSION),
      },
    };

    setAppData(userData);
  }, [auth.userData]);

  return (
    <SelfOnboardingProvider userdata={appData}>
      <PackProvider>
        <BdsGrid id="main-content" direction="column">
          <Header />
          <Steps />
          <Footer />
        </BdsGrid>
      </PackProvider>
    </SelfOnboardingProvider>
  );
};

export default SelfOnboarding;
