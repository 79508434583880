import { Resource } from 'typings/Resource';
import { Requests } from './Requests';

const saveResourceData = async (request: Requests, resources: Resource[]) => {
  const responses = [];
  for (const res of resources) {
    const response = await request.setResource(res.value, res.name, crypto.randomUUID());
    responses.push({ [res.name]: response });
  }
  return responses;
};

export default saveResourceData;
