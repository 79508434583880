import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;

  > bds-stepper {
    margin: 20px 0;
    display: block;
  }

  & .stepper__container {
    @media screen and (max-width: 500px) {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const ContentContainer = styled.div`
  max-width: 960px;
  margin: 44px auto;
  flex-grow: 1;
  @media screen and (max-width: 500px) {
    margin: 30px auto;
  }
`;
