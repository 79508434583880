import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useHashPrevention = (route: string) => {
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(window.location.search.replace('#', '?'));
    const params = ['code', 'scope', 'state', 'session_state'];

    const hasCodeInUrl = (): boolean => {
      for (let i = 0; i < params.length; i++) {
        const param = params[i];

        if (searchParams.get(param) || hashParams.get(param)) {
          return true;
        }
      }

      return false;
    };

    const removeHashes = (): string => {
      const newSearchParams = new URLSearchParams();

      searchParams.forEach((value, key) => {
        if (params.indexOf(key) == -1) {
          newSearchParams.append(key, value);
        }
      });

      const search = newSearchParams.toString();
      return search ? `?${search}` : '';
    };

    if (hasCodeInUrl()) {
      const search = removeHashes();

      navigate(route + search);
    }
  }, [navigate, route]);
};
