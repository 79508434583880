import { useAppContext } from 'contexts/AppContext';
import { useAuth } from 'oidc-react';
import React, { useState } from 'react';
import { Container } from './styles';

const Header: React.FC = () => {
  const auth = useAuth();
  const { menuStructure } = useAppContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const logout = async () => {
    await auth.signOutRedirect();
    await auth.userManager.clearStaleState();

    const urlRedirect = encodeURI(window.location.href);
    const urlLogout = `${process.env.REACT_APP_AUTHORITY}/logout/redirect?returnDesiredUrl=${urlRedirect}`;
    window.location.href = urlLogout;
  };

  const buildMenuStructure = () => {
    return menuStructure.map((menuItem, idx) =>
      menuItem.type === 'item' ? (
        <bds-menu-action
          key={idx}
          button-text={menuItem.title}
          onClick={() => window.open(menuItem.url, menuItem.target)}
        />
      ) : (
        <bds-menu-separation key={idx} size="default" value={menuItem.title} />
      ),
    );
  };

  return (
    <bds-grid>
      <Container>
        <bds-typo variant="fs-16" bold="bold">
          Olá, {auth.userData?.profile.FullName} ;-)
        </bds-typo>
        <bds-button
          onClick={() => setIsMenuOpen(prevState => !prevState)}
          id="menu-button"
          icon="menu-hamburger"
          variant="secondary"
        >
          Menu
        </bds-button>
      </Container>
      <bds-menu menu="menu-button" open={isMenuOpen}>
        {buildMenuStructure()}
        <bds-menu-action button-text="Logout" onClick={logout} />
      </bds-menu>
    </bds-grid>
  );
};

export default Header;
