import React from 'react';
import Rectangle from 'assets/Rectangle.svg';
import { ImageContainer } from './styles';

interface ImageProps {
  srcImage: string;
  srcSmallImage: string;
  addRectangle?: boolean;
}

const Image: React.FC<ImageProps> = ({ srcImage, srcSmallImage, addRectangle = false }) => {
  return (
    <>
      {window.outerWidth <= 500 ? (
        <ImageContainer>
          <img src={srcSmallImage} alt="Blip" />
        </ImageContainer>
      ) : (
        <div>
          <img src={srcImage} alt="Blip" style={{ width: '100%' }} />
          {addRectangle ?? <img src={Rectangle} alt="Elipse" />}
        </div>
      )}
    </>
  );
};

export default Image;
