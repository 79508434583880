export const UpdateContactHubspot = {
  Status: 'blip_go_status_do_contato',
  Lead: 'Lead',
  ContaCriada: 'Conta criada',
  BlipGoInstalado: 'Blip Go! instalado',
  BlipGoFreeConectado: 'Blip Go! Free conectado',
  PlanoPROSolicitado: 'Plano PRO solicitado',
  PlanoPROAtivo: 'Plano PRO ativo',
  Churn: 'Churn',
};

export const LeadOrigin = {
  Plg: 'plg',
  QueryParam: 'lead_origin',
  HubspotProperty: 'e__product_led_growth__plg___',
};
