import React from 'react';

type breakPoint = 'auto' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

interface GridContentProps {
  xxs?: breakPoint;
  xs?: breakPoint;
  sm?: breakPoint;
  md?: breakPoint;
  lg?: breakPoint;
  xg?: breakPoint;
  direction?: 'row' | 'column' | undefined;
  container?: boolean;
  padding?: '1' | '2' | '3' | '4' | undefined;
  style?: object;
  gap?: '4' | undefined;
}

const GridContent: React.FC<GridContentProps> = ({
  children,
  xxs = '12',
  xs = '12',
  sm = '12',
  md = '12',
  lg = '12',
  xg = '12',
  direction,
  container = true,
  padding = '1',
  gap,
  style,
}) => {
  return (
    <bds-grid
      xxs={xxs}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xg={xg}
      direction={direction}
      container={container}
      padding={padding}
      style={style}
      gap={gap}
    >
      {children}
    </bds-grid>
  );
};

export default GridContent;
