import Takeblipblacklogo from 'assets/Takeblipblack.svg';
import { BdsGrid } from 'blip-ds/dist/blip-ds-react';
import React, { useEffect, useState } from 'react';
import { Background, Container } from './styles';

const Footer: React.FC = () => {
  const projectVersion = process.env.REACT_APP_VERSION;

  const [isContentScrollable, setIsContentScrollable] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const contentHeight = document.getElementById('main-content')?.clientHeight;
      if (contentHeight && contentHeight > windowHeight) {
        setIsContentScrollable(true);
      } else {
        setIsContentScrollable(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Background isContentScrollable={isContentScrollable}>
      <Container>
        <BdsGrid alignItems="center">
          <img src={Takeblipblacklogo} alt="Blip" />
          <bds-typo variant="fs-12">
            R. Sergipe. 1440 - Savassi - Belo Horizonte - MG | Alameda Vicente Pinzon, 54 - Vila Olímpia - São Paulo -
            SP
          </bds-typo>
        </BdsGrid>
        {projectVersion && <bds-typo variant="fs-12">v{projectVersion}</bds-typo>}
      </Container>
    </Background>
  );
};

export default Footer;
