import { Installation } from 'typings/Installation';

export const GetRouterDataFromInstallation = (installation: Installation) => {
  return {
    shortName: installation.routerShortName,
    accessKey: installation.routerAccessKey,

    skillTransbordo: {
      shortName: installation.deskShortName,
      accessKey: installation.deskAccessKey,
    },
  };
};
