const ExternalUrls = {
  blipchatPrd: 'chat.blip.ai',
  blipchatHmg: 'hmg-chat.blip.ai',
  packManagerPrd: 'https://packs-blipgo-web-packmanager.cs.blip.ai/pack-manager',
  packManagerPrdV2: 'https://packs-blipgo-web.cs.blip.ai',
  packManagerHmg: 'https://packs-blipgo-web-packmanager.hmg-packs.blip.ai/pack-manager',
  packManagerHmgV2: 'https://packs-blipgo-web.hmg-packs.blip.ai',
  deskPrd: 'https://desk.blip.ai',
  deskHmg: 'https://hmg-desk.blip.ai',
  chatWhatsApp: 'https://wa.me',
  hubspotTicketApiHmg: 'https://whatsappactivation.hmg-cs.blip.ai/api/Hubspot/create-ticket-pipe-embedded-signup',
  hubspotTicketApiPrd: 'https://whatsappactivation.cs.blip.ai/api/Hubspot/create-ticket-pipe-embedded-signup',
};

export default ExternalUrls;
