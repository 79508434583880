import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SO_API_CALL, SO_API_CALL_ERROR } from 'constants/TrackingEvents';
import { ILogArgs, json, logger } from 'packs-template-baseweb';
import qs from 'qs';
import { logTrack } from './LogTrackService';

export class ApiService {
  private api: AxiosInstance;
  private logArgs: ILogArgs;

  constructor(baseURL: string, access_token?: string) {
    this.api = axios.create({
      baseURL: `${baseURL}`,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: access_token ? `Bearer ${access_token}` : '',
      },
      validateStatus: status => status >= 200 && status < 300,
    });

    this.logArgs = { className: 'ApiService' };
  }

  private logError(method: string, url: string, error: any): void {
    logTrack(SO_API_CALL_ERROR, {
      apiCall: `${method.toUpperCase()} ${url}`,
      status: 'Falha',
      error: error.message || error.toString(),
    });

    this.logArgs.methodName = method;

    logger.error(`Error on ${method} request to '${url}': ${json.format(error)}`, this.logArgs);
  }

  private async executeRequest<T>(method: () => Promise<any>, url: string, methodName: string): Promise<T> {
    let attempt = 1;
    const maxAttempts = 3;

    while (attempt <= maxAttempts) {
      try {
        const response = await method();
        logTrack(SO_API_CALL, { apiCall: `${methodName} ${url}`, status: 'Success' });
        return response.data;
      } catch (error: any) {
        this.logArgs.methodName = 'executeRequest';
        if (error.code === 'ERR_NETWORK' && attempt < maxAttempts) {
          logger.warn(`Network error occurred. Retrying request to  (${attempt}/${maxAttempts})...`, this.logArgs);
          attempt++;
        } else {
          this.logError(methodName, url, error);
          throw error;
        }
      }
    }

    throw new Error(`Exceeded maximum retry attempts for ${methodName} request to '${url}'`);
  }

  public async get<T = any>(url: string, params?: any, customConfig?: AxiosRequestConfig): Promise<T> {
    return this.executeRequest<T>(() => this.api.get<T>(url, { params, ...customConfig }), url, 'GET');
  }

  public async post<T = any>(url: string, data?: any, customConfig?: AxiosRequestConfig): Promise<T> {
    return this.executeRequest<T>(() => this.api.post<T>(url, data, customConfig), url, 'POST');
  }

  public async patch<T = any>(url: string, data?: any, customConfig?: AxiosRequestConfig): Promise<T> {
    return this.executeRequest<T>(() => this.api.patch<T>(url, data, customConfig), url, 'PATCH');
  }

  public async delete<T = any>(url: string, params?: any, customConfig?: AxiosRequestConfig): Promise<T> {
    return this.executeRequest<T>(() => this.api.delete<T>(url, { params, ...customConfig }), url, 'DELETE');
  }
}
