import { ILogArgs, json, logger } from 'packs-template-baseweb';
import { GetAttendanceQueues } from './data/set/GetAttendanceQueues';
import { SetAttendanceQueue } from './data/set/SetAttendanceQueue';
import { SetAttendanceRule } from './data/set/SetAttendanceRule';
import { SetAttendant } from './data/set/SetAttendant';
import { SetResource } from './data/set/SetResource';

type QueueResource = {
  id: string;
  ownerIdentity: string;
  name: string;
  isActive: boolean;
  storageDate: string;
  Priority: number;
  uniqueId: string;
};

export class Requests {
  private api: any;
  private logArgs: ILogArgs;

  constructor(api: any) {
    this.api = api;
    this.logArgs = {
      className: 'Requests',
    };
  }

  private async executeRequest(data: any, isFatal: boolean = false) {
    try {
      const response = await this.api.post('/commands', data);

      if (response.data.status === 'failure') {
        throw new Error(response.data);
      }

      return response.data;
    } catch (error) {
      const message = `Problem on doing the following request to Blip
      | api: '${json.format(this.api.defaults)}'
      | data: '${json.format(data)}'
      | error: '${json.format(error)}`;

      this.logArgs.methodName = 'executeRequest';

      if (isFatal) logger.fatal(message, this.logArgs);
      else logger.error(message, this.logArgs);
    }
  }

  async setAttendanceQueue(ownerIdentity: string, queueName: string, guid: string) {
    const data = SetAttendanceQueue({
      ownerIdentity,
      queueName,
      guid,
    });

    return await this.executeRequest(data);
  }

  async setAttendant(attendantIdentity: string, teams: string[], guid: string) {
    const data = SetAttendant({
      attendantIdentity,
      teams,
      guid,
    });

    return await this.executeRequest(data);
  }

  async setAttendanceRule(ownerIdentity: string, queueName: string, queueId: string, guid: string) {
    const data = SetAttendanceRule({
      ownerIdentity,
      queueName,
      queueId,
      guid,
    });

    return await this.executeRequest(data);
  }

  async setResource(resourceValue: string, resourceName: string, guid: string) {
    const data = SetResource({
      resourceValue,
      resourceName,
      guid,
    });

    return await this.executeRequest(data);
  }

  async getAllAttendanceQueues() {
    const response = await this.executeRequest(GetAttendanceQueues());
    const queues = response.resource.items as QueueResource[];
    return queues;
  }
}
