import Guid from 'guid';
import { api } from 'services/requests/Api';
import { Requests } from 'services/requests/Requests';
import saveResourceData from 'services/requests/saveResourceData';

interface SaveQueueProps {
  emails: string[];
  deskShortName: string;
  deskKey: string;
  routerShortName: string;
  routerKey: string;
}

const QUEUES_DEFAULT = ['Default', 'Atendimento'];
const PRE_SERVICE_QUESTION_ATTENDANCE_QUEUE = 'Para agilizar seu atendimento, por favor nos informe seu nome 😉';

export const SaveQueue = async ({ emails, deskKey, deskShortName, routerKey, routerShortName }: SaveQueueProps) => {
  const deskRequest = new Requests(api(deskShortName, deskKey));
  const guid = Guid.raw();

  const setNewAttendant = async (email: string) => {
    const attendantIdentity = email.replace('@', '%40').concat('@blip.ai');
    await deskRequest.setAttendant(attendantIdentity, QUEUES_DEFAULT, guid);
  };

  const ownerIdentity = deskShortName + '@msging.net';

  const currentQueues = await deskRequest.getAllAttendanceQueues();
  let attendanceQueue = currentQueues.find(queue => queue.name === QUEUES_DEFAULT[1]);

  if (!attendanceQueue) {
    attendanceQueue = await deskRequest.setAttendanceQueue(ownerIdentity, QUEUES_DEFAULT[1], guid);
    if (!attendanceQueue) {
      throw new Error(`Erro ao criar fila de atendimento: ${QUEUES_DEFAULT[1]}`);
    }
  }

  await deskRequest.setAttendanceRule(ownerIdentity, QUEUES_DEFAULT[1], attendanceQueue.uniqueId, guid);

  const request = new Requests(api(routerShortName, routerKey));

  const preServiceQuestion = [{ [QUEUES_DEFAULT[1]]: PRE_SERVICE_QUESTION_ATTENDANCE_QUEUE }];

  await saveResourceData(request, [
    {
      name: 'preServiceQuestionList',
      type: 'text/plain',
      value: JSON.stringify(preServiceQuestion),
    },
    {
      name: 'filaNumero',
      type: 'text/plain',
      value: 'true',
    },
  ]);

  for (const email of emails) {
    await setNewAttendant(email);
  }
};
