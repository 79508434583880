import { ApiService } from './Api';

const UPDATE_CONTACT_HUBSPOT = 'v1/hubspot/contact';

type UPDATE_CONTACT_BODY = {
  properties: UPDATE_CONTACT_PROPERTIES[];
};

export type UPDATE_CONTACT_PROPERTIES = {
  property: string;
  value: string;
};

const ApiServer = process.env.REACT_APP_API_SERVER ?? '';
const ApiPort = process.env.REACT_APP_API_PORT ?? '';

export const handleUpdateContactHubspot = async (
  updateContactProperty: UPDATE_CONTACT_PROPERTIES[],
  accessToken: string,
) => {
  await updateContactBlipGo(updateContactProperty, accessToken);
};

const updateContactBlipGo = async (properties: UPDATE_CONTACT_PROPERTIES[], accessToken: string) => {
  try {
    const apiService = new ApiService(ApiServer, accessToken);
    const updateContactBody = { properties: properties } as UPDATE_CONTACT_BODY;

    await apiService.post(UPDATE_CONTACT_HUBSPOT, updateContactBody);
  } catch (error) {
    return null;
  }
};
