import Banner from 'components/Banner';
import Stepper from 'components/Stepper';
import { ROUTE_CONCLUSION, ROUTE_INSTALLATION, ROUTE_INSTALLATIONS_ERROR, ROUTE_ONBOARDING } from 'constants/Routes';
import { usePackContext } from 'contexts/PackContext';
import Conclusion from 'pages/Conclusion';
import Installation from 'pages/Installation';
import InstallationError from 'pages/InstallationError/InstallationError';
import Welcome from 'pages/Welcome';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Params } from 'typings/Params';
import { Container, ContentContainer } from './styles';

const Steps: React.FC = () => {
  const { pack } = usePackContext();
  const navigate = useNavigate();

  useEffect(() => {
    const sourceParams = localStorage.getItem('source');
    let params: Params = {};
    if (sourceParams !== null) {
      params = JSON.parse(sourceParams);
    }
    switch (pack.installationStatus) {
      case 'Completed':
        const packManagerUrl = process.env.REACT_APP_PACK_MANAGER_REDIRECT_URL;
        if (params.plan_type) {
          window.open(`${packManagerUrl}?plan_type=${params.plan_type}`, '_self');
        } else {
          window.open(packManagerUrl, '_self');
        }

        break;
      case 'InProgress':
        navigate(ROUTE_INSTALLATION);
        break;
      case 'Canceled':
      case 'InternalError':
        navigate(ROUTE_INSTALLATIONS_ERROR);
        break;
    }
  }, []);

  return (
    <div>
      <Banner />
      <Container>
        <Stepper />
        <ContentContainer>
          <Routes>
            <Route index element={<Welcome />} />
            <Route path="/" element={<Navigate to={ROUTE_ONBOARDING} />} />
            <Route path={ROUTE_INSTALLATION} element={<Installation />} />
            <Route path={ROUTE_CONCLUSION} element={<Conclusion />} />
            <Route path={ROUTE_INSTALLATIONS_ERROR} element={<InstallationError />} />
          </Routes>
        </ContentContainer>
      </Container>
    </div>
  );
};

export default Steps;
