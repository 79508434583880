import styled from 'styled-components';

export const ContainerActions = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 24px;
`;

export const ContainerInputLabel = styled.div`
  align-items: center;
  padding-bottom: 2px;
  display: flex;

  bds-typo {
    margin-bottom: 4px;
  }

  bds-tooltip {
    height: 18px;
    margin-left: 4px;

    @media screen and (max-width: 500px) {
      display: none;
    }
  }
`;

export const FullWidthContainer = styled.div`
  margin-top: 8px;
  width: 100%;
  display: flex;
`;

export const CenteredContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;
