import axios from 'axios';
import { Environments } from 'constants/Environments';

export const api = (botShortName: string, auth: string) => {
  const url = Environments.IsProduction()
    ? 'https://blipatendimentotrial.http.msging.net'
    : 'https://blipgo-hmg-interno.hmg-http.msging.net';

  return axios.create({
    baseURL: url,
    headers: {
      Authorization: auth,
    },
  });
};
