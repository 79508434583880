import { EventData } from 'typings/EventData';
import { LogOut } from './Auth';
import { track } from './Segment';

type EventCallbackOpts = {
  [key: string]: (data: EventData) => EventData;
};

enum EventStatus {
  Success = 'Success',
  Error = 'Error',
}

export const PluginEvents: EventCallbackOpts = {
  BLIP_PACKS_EVENTS_TRACK_DATA: (data: EventData) => {
    const { event, payload } = data.data as { event: string; payload?: any };

    try {
      track(event, payload);
      return new EventData(data.eventId, data.eventName, {
        result: EventStatus.Success,
      });
    } catch {
      return new EventData(data.eventId, data.eventName, {
        result: EventStatus.Error,
      });
    }
  },

  BLIP_PACKS_EVENTS_LOGOUT: (data: EventData) => {
    try {
      LogOut();
      return new EventData(data.eventId, data.eventName, {
        result: EventStatus.Success,
      });
    } catch {
      return new EventData(data.eventId, data.eventName, {
        result: EventStatus.Error,
      });
    }
  },
};
