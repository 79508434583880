import blipColors from 'blip-tokens/build/json/colors.json';
import styled from 'styled-components';

export const Container = styled.div`
  z-index: 99999 !important;
  position: fixed;
  padding-top: 40vh;
  margin-top: -4rem;
  width: 100%;
  height: 105vh;
  background: ${blipColors['color-extend-grays-blackwidow']};
  opacity: 0.5 !important;
  overflow: hidden;
`;

export const ContainerCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: loader-pulse 2s infinite ease-out;

  @keyframes loader-pulse {
    0% {
      transform: scale(0.5); /* scaling to 0 */
    }
    50% {
      transform: scale(1); /* increasing the size */
    }
    70% {
      transform: scale(0.65); /* decreasing the size */
    }
    100% {
      transform: scale(0.5); /* seeting back to initial size */
    }
  }
`;
