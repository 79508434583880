import ImageLoader from 'assets/BlipLoaderLogo.svg';
import React from 'react';
import { Container, ContainerCenter } from './styles';

const Spinner: React.FC = () => {
  return (
    <Container id="loader">
      <ContainerCenter>
        <img src={ImageLoader} />
      </ContainerCenter>
    </Container>
  );
};

export default Spinner;
