import {
  INSTALLATIONS_CHANNEL_ACTIVATED_ROUTE,
  INSTALLATION_BY_ID,
  INSTALLATION_DETAILS,
  INSTALL_CHATBOT_ROUTE,
  PACK_INSTALLATIONS_ROUTE,
  PLUGIN_ROUTE,
  ROUTER_SHORTNAME,
} from 'constants/ApiRoutes';
import { usePackContext } from 'contexts/PackContext';
import { useSelfOnboardingContext } from 'contexts/SelfOnboardingContext';
import { PackApprovalStatus } from 'enums';
import { ILogArgs, logger } from 'packs-template-baseweb';
import { useCallback } from 'react';
import { GetRouterDataFromInstallation } from 'services/PackService';
import { Installation, InstallationDetails } from 'typings/Installation';
import { Pack } from 'typings/Pack';
import { PluginData } from 'typings/PluginData';

const logArgs: ILogArgs = {
  className: 'usePack',
};

export type Application = {
  id: string;
  status?: PackApprovalStatus;
  name?: string;
  description?: string;
  channels?: string;
  version?: string;
  routerShortName?: string;
  plugin?: PluginData;
};

export const usePack = () => {
  logArgs.className = 'usePack';

  const { apiService, settings, profile } = useSelfOnboardingContext();
  const { pack } = usePackContext();
  const isHomologation = settings.Environment == 'HMG' || settings.Environment == 'staging';
  const defaultPackId = isHomologation ? '430' : '60';
  const packId = process.env.REACT_APP_BLIP_GO_ID ? process.env.REACT_APP_BLIP_GO_ID : defaultPackId;

  const getPackNameByPackId = useCallback(
    (packId: number) => {
      logArgs.methodName = 'getPackNameByPackId';
      const packNamesById = {
        [isHomologation ? settings.BlipGoIdHmg : settings.BlipGoId]: 'blipgo',
      };
      logger.warn(`packNamesById[packId]: ${packNamesById[packId]}`, logArgs); //blipgo
      return packNamesById[packId] ? packNamesById[packId] : '';
    },
    [isHomologation, settings],
  );

  const getPackName = useCallback(
    (packId: string) => {
      return getPackNameByPackId(+packId);
    },
    [getPackNameByPackId],
  );

  const getLastInstallationAndDetails = useCallback(
    async (installations: Installation[]) => {
      const currentInstallation = installations.reduce((prev, current) => {
        return prev.installationId > current.installationId ? prev : current;
      });

      const installationDetails = await apiService.get<InstallationDetails>(
        `${INSTALLATION_BY_ID}/${currentInstallation.installationId}`,
      );
      return { currentInstallation, installationDetails };
    },
    [apiService],
  );

  const fetchPluginFromPack = useCallback(
    async (packId: string) => {
      return await apiService.get<PluginData>(`${PLUGIN_ROUTE}/${packId}`, false);
    },
    [apiService],
  );

  const fetchPackData = useCallback(
    async (packId: string) => {
      let installations: any;
      let pack: Pack = {} as Pack;
      let isOwner = false;

      const profileEmail = profile?.email ?? '';

      if (packId) {
        installations = await apiService.get<Installation[]>(`${PACK_INSTALLATIONS_ROUTE}?packId=${packId}`);
        if (installations?.installations) {
          installations = installations.installations;
        }
      } else {
        installations = await apiService.get<Installation[]>(
          `${INSTALLATIONS_CHANNEL_ACTIVATED_ROUTE}/${profileEmail}`,
        );
      }

      if (installations.length == 0) {
        pack.id = packId ? packId.toString() : '';
        pack.name = getPackName(pack.id);

        pack.tenant = { id: '' };

        return { pack, isOwner };
      }

      isOwner = true;

      const installationData: { currentInstallation: Installation; installationDetails: InstallationDetails } =
        await getLastInstallationAndDetails(installations);

      pack = {
        id: installationData.currentInstallation.applicationId,
        routerData: GetRouterDataFromInstallation(installationData.currentInstallation) as any,
        isChannelActivated: installationData.currentInstallation.isChannelActived,
        pluginData: await fetchPluginFromPack(installationData.currentInstallation.applicationId),
        name: getPackName(installationData.currentInstallation.applicationId),
        tenant: installationData.installationDetails.tenant,
      };

      return { pack, isOwner, installationData };
    },
    [apiService, fetchPluginFromPack, getLastInstallationAndDetails, getPackName, profile?.email],
  );

  const getRouterShortName = useCallback(async () => {
    if (!profile?.email) return location.reload();
    try {
      const routerShortname = await apiService.get<string>(
        `${ROUTER_SHORTNAME}?packId=${packId}&identity=${encodeURIComponent(profile.email)}`,
      );
      return routerShortname;
    } catch (error) {
      return false;
    }
  }, [apiService, packId, profile?.email]);

  const getInstallationDetails = useCallback(
    async (routerShortName: string) => {
      try {
        const installation = await apiService.get<Installation>(`${INSTALLATION_DETAILS}?shortName=${routerShortName}`);
        return installation;
      } catch (error) {
        logArgs.methodName = 'getInstallationDetails';
        logger.error(`Installation details error: ${error}`, logArgs);
        const err = error as Error;
        return new Error(`Error on get installation details: ${err.message}`);
      }
    },
    [apiService],
  );

  const installPack = async (companyName: string): Promise<Pack> => {
    try {
      const body = {
        applicationId: packId,
        clientIdentity: companyName,
        clientEmail: profile?.Email,
        tenant: pack.tenant.id,
      };
      const installation = await apiService.post<Pack>(INSTALL_CHATBOT_ROUTE, JSON.stringify(body));
      return Promise.resolve(installation);
    } catch (error: any) {
      const err = error as Error;
      logArgs.methodName = 'installPack';
      logger.error(`Create installation error: ${err.message}`, logArgs);
      if (error.response) {
        return Promise.reject(error.response.data.Error.Message);
      } else {
        return Promise.reject(err.message);
      }
    }
  };

  return {
    fetchPackData,
    getRouterShortName,
    getInstallationDetails,
    installPack,
  };
};
