import Rocket from 'assets/Rocket.svg';
import { BdsButton, BdsGrid } from 'blip-ds/dist/blip-ds-react';
import GridContent from 'components/GridContent';
import ExternalUrls from 'constants/ExternalUrls';
import { SO_CONCLUSION_LINK_REDIRECT, SO_FINISHED } from 'constants/TrackingEvents';
import { LeadOrigin, UpdateContactHubspot } from 'constants/UpdateContactHubspot';
import { usePackContext } from 'contexts/PackContext';
import { useSelfOnboardingContext } from 'contexts/SelfOnboardingContext';
import { Step } from 'enums';
import { useSession } from 'hooks/useSession';
import { useTrack } from 'hooks/useTrack';
import { useAuth } from 'oidc-react';
import { ILogArgs, json, logger } from 'packs-template-baseweb';
import React, { useEffect, useState } from 'react';
import { handleUpdateContactHubspot } from 'services/HubspotIntegration';
import { createToastError } from 'services/Toast';
import { api } from 'services/eCluster/Api';
import { EclusterRequets } from 'services/eCluster/EclusterRequets';
import { HelpLink, ImageContentCenter } from 'styles';
import { Params } from 'typings/Params';

const Conclusion: React.FC = () => {
  const logArgs: ILogArgs = {
    className: 'Conclusion',
  };

  const { userData } = useAuth();
  const { setActiveStep, settings, leadOrigin } = useSelfOnboardingContext();
  const { pack } = usePackContext();
  const { deleteSession } = useSession();
  const { track } = useTrack();
  const [showTryAgainBanner, setShowTryAgainBanner] = useState(false);
  const sourceUtm = localStorage.getItem('source');
  const isHomologation = settings.Environment == 'HMG';
  const packManagerEnvUrlV2 = isHomologation ? ExternalUrls.packManagerHmgV2 : ExternalUrls.packManagerPrdV2;

  let params: Params = {};
  if (sourceUtm !== null) {
    params = JSON.parse(sourceUtm);
  }

  const trackAndRedirectToPackManager = async () => {
    setShowTryAgainBanner(false);
    track(SO_CONCLUSION_LINK_REDIRECT);
    try {
      await saveDirectionLeadInfo();
      if (params.plan_type) {
        window.open(`${packManagerEnvUrlV2}?plan_type=${params.plan_type}`, '_self');
      } else {
        window.open(packManagerEnvUrlV2, '_self');
      }
    } catch (error) {
      setShowTryAgainBanner(true);
      createToastError('Falha ao finalizar sua configuração', 'Por favor, tente novamente.');
    }
  };

  const saveDirectionLeadInfo = async () => {
    try {
      const EclusterApi = new EclusterRequets(
        api(
          process.env.REACT_APP_URL_API_ECLUSTER_USER as string,
          process.env.REACT_APP_URL_API_ECLUSTER_PASSWORD as string,
        ),
      );
      await EclusterApi.CreateDirectionLead(
        {
          installationId: pack.installationId,
          nameMerchant: userData?.profile.Company ?? pack.companyName,
          emailMerchant: userData?.profile.email,
          leadSource: params.si?.toUpperCase() ?? 'Other',
          productVersion: 'V2',
        },
        true,
      );
    } catch (error: any) {
      logArgs.methodName = 'saveDirectionLeadInfo';
      const message = `Failed to create direction lead registry
        | error: '${json.format(error)}`;
      logger.error(message, logArgs);
      throw new Error(`${json.format(error)}`);
    }
  };

  useEffect(() => {
    deleteSession();
    track(SO_FINISHED);
    setActiveStep(Step.Conclusion);

    Promise.all([updateContactHubspot(), updateLeadOriginHubspot()]);

    setTimeout(async () => {
      trackAndRedirectToPackManager();
    }, 3000);
  }, []);

  const updateContactHubspot = async () => {
    await handleUpdateContactHubspot(
      [
        {
          property: UpdateContactHubspot.Status,
          value: UpdateContactHubspot.BlipGoInstalado,
        },
      ],
      userData?.access_token ?? '',
    );
  };

  const updateLeadOriginHubspot = async () => {
    if (!leadOrigin) return;

    await handleUpdateContactHubspot(
      [
        {
          property: LeadOrigin.HubspotProperty,
          value: leadOrigin === LeadOrigin.Plg ? 'true' : 'false',
        },
      ],
      userData?.access_token ?? '',
    );
  };

  return (
    <GridContent padding="3" style={{ height: screen.height <= 500 ? '100%' : 'calc(100vh - 300px)' }}>
      <bds-grid md="7" lg="7" xg="7" gap="1" direction="column">
        <bds-typo variant="fs-32" bold="bold" tag="h2">
          Tudo pronto! 🎉
        </bds-typo>
        <bds-typo variant="fs-16">
          Agora chegou a hora de <b>conhecer o {pack.name ? pack.name : 'seu pack'}!</b>
        </bds-typo>
        <bds-typo>
          Acesse a plataforma do Blip Go! Nela é possível criar seu chatbot, atender as solicitações de contato, editar
          as suas informações e muito mais!&nbsp;
          <HelpLink onClick={trackAndRedirectToPackManager}>clique aqui</HelpLink>
        </bds-typo>
        {showTryAgainBanner && (
          <BdsGrid margin="t-2" gap="2" direction="column" padding="none">
            <bds-toast
              icon="robot-5"
              toast-title="Quase lá..."
              toast-text="Falta pouco para concluirmos, mas parece que algo deu errado. Por favor, tente mais uma vez."
              variant="warning"
              show={true}
            ></bds-toast>
            <BdsButton onBdsClick={trackAndRedirectToPackManager} variant="ghost">
              Tentar novamente
            </BdsButton>
          </BdsGrid>
        )}
      </bds-grid>

      <ImageContentCenter>
        <bds-grid md="5" lg="5" xg="5">
          <img src={Rocket} alt="Blip Rocket" width="350px" />
        </bds-grid>
      </ImageContentCenter>
    </GridContent>
  );
};

export default Conclusion;
